import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ShipkooShippingImg from 'images/blog-images/shipkoo-shipping.png';
import ShipkooExpressShippingImg from 'images/blog-images/shipkoo-express-shipping.png';
import HolidayShippingFeatured from 'images/featured-image/holiday-shipping-and-fulfillment-strategies-2020-featured.png';
import HolidayShippingImg from 'images/blog-images/holiday-shipping-and-fulfillment-strategies-2020.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query HolidayShippingImg {
      file(relativePath: { eq: "holiday-shipping-and-fulfillment-strategies-2020.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="holiday shipping and fulfillment strategies in 2020"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Holiday Shipping & Fulfillment Strategies in 2020 | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-holiday-shipping" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Holiday Shipping & Fulfillment Strategies in 2020",
              content: intl.formatMessage({ id: "blog-post-meta-title-holiday-shipping" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: HolidayShippingFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="holiday-shipping-and-fulfillment-strategies-in-2020"
        title="Holiday Shipping & Fulfillment Strategies in 2020"
        date="2020-10-27"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Your sales probably increase dramatically during holiday seasons, which also bring a striking increase to your shipping and fulfillment. It is important to prepare your logistics operations as well as fulfillment properly well ahead of the holiday season. Take your time to plan a holiday shipping strategy accordingly so you do not get overwhelmed. Due to the COVID-19 pandemic, it appears that lockdown has driven people online for everything, not just essential supplies. Therefore, getting prepared for Black Friday and Christmas will help you stay on top of mishaps such as out of stocking, delayed shipments and missing packages.
            </p>

            <Image src={HolidayShippingImg} className="" alt="holiday shipping and fulfillment strategies"/>

            <h5>
              <strong>
                Reasonable Stocking
              </strong>
            </h5>

            <p>
              Some sellers have as many as thousands of product SKUs in their stores. Limited by inventory pressure and product sales, it is impossible to keep enough inventory for each SKU, which is neither realistic nor desirable. So sellers need to consider which products in the store to stock up? How many stocks do you need to be prepare for?
            </p>

            <p>
              Selection of stock products:
            </p>

            <p>
              a. Products whose in-store sales continue to rise
            </p>

            <p>
              b. Products with high praise rate
            </p>

            <p>
              c. Products that contribute more to store traffic
            </p>

            <p>
              d. Products with profit margins and good cost performance
            </p>

            <p>
              e. Products with obvious differentiated competitive advantages
            </p>

            <p>
              f. Products that have not been promoted but continue to have orders
            </p>

            <p>
              In view of the first two situations, AliExpress sellers judge the future sales of products based on the sales in the past 3 months, and screen out products that meet the expected growth for planning; products with traffic and products that have not been promoted but continue to have ordered, The seller should combine the data analysis to see whether the product is worth optimizing and promoting. In this case, consider the number of stocks.
            </p>

            <Image src={ShipkooShippingImg} className="" alt="shipkoo shipping"/>

            <h5>
              <strong>
                Risk Assessment
              </strong>
            </h5>

            <p>
              After the first evaluation and screening, we know which products to stock in the store. In order to prevent the store from being unable to support various expenses due to insufficient cash flow, we need to solve the following problems:
            </p>

            <OrderedList>
              <li>
                What is the estimated number of orders received during the Black Friday or Christmas pre-heating period and the event period? How many packing and shipping needs to be arranged during this period? What should be said about product knowledge related to the training of shipping personnel? When will it be arranged?
              </li>
              <li>
                The calculated stock quantity, how long can it be digested later if it cannot be sold?
              </li>
              <li>
                How much profit is for the registration and the key products? How much inventory does not cause pressure?
              </li>
              <li>
                How much cash flow is needed for the calculated stock quantity? Will it affect the normal capital turnover of the store before and after the big promotion?
              </li>
              <li>
                If there is a shortage of goods, how long will it take to replenish the goods? How long is it expected to arrive? Are there alternative solutions for operations?
              </li>
            </OrderedList>

            <h5>
              <strong>
                Improve Timeliness
              </strong>
            </h5>

            <p>
              In view of the improvement of delivery timeliness, we should do our best in the links that we can control. For example, to minimize the delivery time, we can optimize the picking, quality inspection, packaging, packaging, and delivery from the warehouses.
            </p>
            <OrderedList>
              <li> 
                Products that have been sold and transformed should be placed closer to the entrance, and vice versa. Seasonal products should be placed according to seasonal characteristics.
              </li>
              <li>
                The same category or similar items can be kept in the same place to facilitate inquiry and operation.
              </li>
              <li>
                The heavy material is placed below, and the light material is placed away from the hand or above, measured by waist height. The heavy objects that need to be handled manually should be placed in consideration of the height of the waist of the human body while ensuring safety.
              </li>
            </OrderedList>

            <Image src={ShipkooExpressShippingImg} className="" alt="shipkoo express shipping"/>

            <p>
              Shipkoo is here to make your holiday season stress-free by providing reliable order fulfillment strategies and exclusive <a href="https://shipkoo.com/services/express-shipping/">express shipping</a> end-to-end. Shipkoo provides multiple and affordable shipping routes for different destinations based on your needs. Our global fulfillment centers also allow you to pick, pack and dispatch your orders from any of our fulfillment centers and ship them to your worldwide customers. You will be able to offer 3-7 days express shipping to your customers and get real-time updates to restock  during the peak holiday season. <a href="https://shipkoo.com/contact/">Contact our shipping specialists</a> today to get your free quote for holiday shipping!
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;